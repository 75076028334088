<template>
  <v-card>
    <!-- <admin-header></admin-header> -->
    <v-toolbar>
      <v-toolbar-title>Mailroom Package List</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined @click="showFilters = !showFilters" style="margin-right:1em">
        <v-icon left>{{ filterActive ? 'fas' : 'fal' }} fa-filter</v-icon>
        {{ showFilters ? 'Hide' : 'Show' }} Filters
      </v-btn>
      <v-btn to="/mailroom/admin/package/add" outlined>
        <v-icon left>fal fa-plus</v-icon>
        Add Packages
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="showFilters">
      <v-row>
        <v-col>
          <v-text-field v-model="filter.barcode" label="Barcode" outlined clearable @input="(barcode) => updateFilter({ barcode })"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="filter.user" label="User Filter" outlined clearable @input="(user) => updateFilter({ user })"></v-text-field>
        </v-col>
        <v-col>
          <v-select v-model="filter.status" :items="statusOptions" label="Package Status" multiple outlined chips small-chips deletable-chips @input="(status) => updateFilter({ status })"></v-select>
        </v-col>
        <v-col>
          <v-select v-model="filter.location" :items="locationOptions" label="Package Location" multiple outlined chips small-chips deletable-chips @input="(location) => updateFilter({ location })"></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="packages" :headers="headers" :server-items-length="packageCount" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }" :options.sync="options" @update:options="loadPackages"  @click:row="(arg, { item }) => $router.push('/mailroom/admin/package/' + item._id)">
      <template v-slot:item.createdAt="{ item }">{{ stringFormatDate(item.createdAt) }}</template>
      <template v-slot:item.daysOnShelf="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ daysOnShelf(item) }}</span>
          </template>
          <span>{{ shelfText(item) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.notifications="{ item }">{{ item.notifications.length }}</template>
    </v-data-table>
  </v-card>
</template>
<style scoped>
.v-data-table >>> tbody > tr {
  cursor: pointer !important;
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const showFilters = ref(false)
    const filter = computed({
      get: () => root.$store.state.mailroom.packageFilter,
      set: (val) => {
        console.log(val)
        root.$store.commit('mailroom/patchPackageFilter', val)
      }
    })
    function updateFilter (val) {
      root.$store.commit('mailroom/patchPackageFilter', val)
      loadPackages()
    }
    const filterActive = computed(() => filter.value.barcode !== '' || filter.value.user !== '' || filter.value.status.length > 0 || filter.value.location.length > 0)

    const statusOptions = ref([])
    const locationOptions = ref([])
    const typeOptions = ref([])
    const packages = ref([])
    const packageCount = ref(0)
    const headers = ref([
      { text: 'Barcode', value: 'barcode' },
      { text: 'Sent To', value: 'user.name' },
      { text: 'Status', value: 'status' },
      { text: 'Location', value: 'location' },
      { text: 'Received Date', value: 'createdAt' },
      { text: 'Days on Shelf', value: 'daysOnShelf', sortable: false },
      { text: 'Notifications', value: 'notifications', sortable: false }
    ])
    const options = computed({
      get: () => root.$store.state.mailroom.packagePagination,
      set: (val) => root.$store.commit('mailroom/setPackagePagination', val)
    })
    async function loadPackages (newOptions) {
      const { page, itemsPerPage, sortBy, sortDesc } = newOptions || options.value
      const query = {
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }
      if (sortBy.length > 0) {
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      } else {
        query.$sort['user.name'] = 1
      }
      if (filter.value.barcode != null && filter.value.barcode !== '') query.barcode = { $regex: filter.value.barcode, $options: 'i' }
      if (filter.value.user != null && filter.value.user !== '') query['user.name'] = { $regex: filter.value.user, $options: 'i' }
      if (filter.value.status.length > 0) query.status = { $in: filter.value.status }
      if (filter.value.location.length > 0) query.location = { $in: filter.value.location }
      const { data, total } = await root.$feathers.service('mailroom/package').find({ query })
      packages.value = data
      packageCount.value = total
    }

    onMounted(() => {
      const optionService = root.$feathers.service('forms/select-options')
      optionService.find({ query: { name: 'Mailroom: Package Status' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            statusOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Location' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            locationOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Type' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            typeOptions.value = options
          })
        }
      })
    })

    function daysOnShelf (item) {
      let end = new Date()
      const start = new Date(item.createdAt)
      for (let i = 0; i < item.history.length; i++) {
        if (item.history[i].status === 'Picked Up') {
          end = new Date(item.history[i].date)
        }
      }
      return Math.floor((end - start) / 86400000)
    }

    function shelfText (item) {
      for (let i = 0; i < item.history.length; i++) {
        if (item.history[i].status === 'Picked Up') {
          return 'Picked up: ' + stringFormatDate(item.history[i].date)
        }
      }
      return 'No pick up date'
    }

    return {
      showFilters,
      filter,
      updateFilter,
      filterActive,
      statusOptions,
      locationOptions,
      typeOptions,
      packages,
      packageCount,
      headers,
      options,
      loadPackages,
      stringFormatDate,
      daysOnShelf,
      shelfText
    }
  }
}
</script>
